import './App.css';
import { Button, Col, Row, Container, Card } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import Campaign from "./Campaign.js"
import { useEffect } from 'react';
import totimage from "./Images/tot-s3.jpg";
import wdimage from "./Images/waterdeep.jpg";
import iwdimage from "./Images/icewind.jpg";

function App() {
  useEffect(() => {
    document.title = "VTT-Controller"
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <Container fluid>
          <Row>
          <Col>
            <Campaign title="Tales of Trunkim" text='Der legendären "Dungeons and Dragons" Epos. Die Handlung neigt sich dem Höhepunkt! Die Spieler verzweifeln und der DM schwitzt.' id="ToT" img={totimage}/>
          </Col>
          <Col>
            <Campaign title="Waterdeep Dragon Heist" text="Neu dabei seit diesem Jahr, wird die berühmteste Stadt der Schwertküste unsicher gemacht." id="WD" img={wdimage}/>
          </Col>
          <Col>
            <Campaign title="Icewind Dale, Rime of the Frostmaiden" text="Ein neues Abenteuer erwartet euch in der Eistundra! Seid ihr gerüstet für die immer währende Kälte? Oder ist euch dieses Abenteuer etwas zu frisch?" id="IWD" img={iwdimage}/>
          </Col>
          </Row>
        </Container>
      </header>
    </div>
  );
}

export default App;
