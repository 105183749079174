import React, {useEffect, useState} from 'react'
import { Button } from 'react-bootstrap'
import axios from "axios"
import LoginForm from "./Login";

const CampaignButton = (props) => {
    const cid = props.cid;
    const [buttonState, setButtonState] = useState('fetching...');
    const [isVisable, setLoginVisible] = useState(false);
    const setButtonText = () => {
        axios.get("https://tales-of-trunkim.de/control_server/campaign", {params: {name:cid}})
        .then((response) => {
            var cstate = response.data.state;
            var btnText = "";
            if (cstate){
                btnText = "Stop";
            }else if (!cstate){
                btnText = "Start";
            }else{
                btnText = "not available";
            }
            setButtonState(btnText);
        })
    }
    const handleLoginClick = () => {
        setLoginVisible((isVisible) => !isVisible);
    }

    const hide = () => {
        setLoginVisible((isVisible) => false);
    }

    const updateButtonText = () => {
        setTimeout(() => setButtonText(), [500]);
        //open new Link
        var prelink = "http://tales-of-trunkim.de:";
        var link = "";
        if (cid === "ToT"){
            link = prelink+"30000";
        }else if (cid === "WD"){
            link = prelink+"31000";
        }else if (cid === "IWD"){
            link = prelink+"32000";
        }
        window.open(link,"_blank");
    }

    //effect
    useEffect(() => {
        setTimeout(() => {
            setButtonText();
        }, [500]);
    }, [buttonState]);
    

    //component
    return(
        <div>
            <LoginForm isVisible={isVisable} cid={cid} operation={buttonState} execAfter={updateButtonText} hide={hide}/>
            <Button variant="primary" onClick={handleLoginClick}>{buttonState}</Button>
        </div>
        )
};

export default CampaignButton