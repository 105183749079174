import React, {useState} from 'react'
import axios from "axios"

const LoginForm = ({ isVisible, cid, operation, execAfter, hide }) => {
    var cid = cid;
    var isVisible = isVisible;
    var operation = operation;
    var exec = execAfter
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post("https://tales-of-trunkim.de/control_server/campaign", {
            contentType: "application/json",
            body: {
                name:cid,
                mode: operation,
                username: username,
                password: password,
            }
        }).then((response) => {
            var res = response.data;
        })
        hide();
        exec();
    }

    return (
        <div className={!isVisible ? "active show" : " show"}>
            <div className="login-form">
                <div className="form-box solid">
                    <form onSubmit={handleSubmit}>
                        <h1 className="login-text">Verify DM</h1>
                        <label>Username</label><br></br>
                        <input type="text" name="username" className="login-box" onChange={e => setUsername(e.target.value)}/><br></br>
                        <label>Password</label><br></br>
                        <input type="password" name="password" className="login-box" onChange={e => setPassword(e.target.value)}/><br></br>
                        <input type="submit" value="LOGIN" className="login-btn"/>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;