import React, {Component} from 'react'
import { Card, Button} from 'react-bootstrap'
import CampaignButton from "./CampaignButton.js"

class Campaign extends Component {
    constructor(props){
        super(props);
        this.title = props.title;
        this.text = props.text;
        this.id = props.id;
        this.click = function () {
            console.log("pressed:" + this.id);
        }
        this.image = props.img;
    }

    render(){
        return(
            <Card className="m-3" style={{color: "#000"}}>
              <Card.Img src={this.image}/>
              <Card.Body>
                <Card.Title>{this.title}</Card.Title>
                <Card.Text>{this.text}
                </Card.Text>
                <CampaignButton cid={this.id}/>
              </Card.Body>
            </Card>
        )
    }
}

export default Campaign